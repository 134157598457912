<template>
  <v-theme-provider light>
    <base-section id="graphics" class="grey lighten-4" space="40">
      <v-container>
        <v-row class="mb-3">
          <v-col md="12">
            <v-card class="mx-auto" v-if="assetInfo && marketStatus">
              <v-card-text>
                <div>Detail Сoin Information</div>
                <div class="display-3 mb-1" style="color: #416fad">
                  {{ assetInfo.name }}
                </div>
                <p>waves blockchain based</p>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("emit_date") }}</b>
                  {{ new Date(assetInfo.issueTimestamp).toLocaleString("ru") }}
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("amount_coins") }}</b>
                  {{
                    (
                      assetInfo.quantity / Math.pow(10, assetInfo.decimals)
                    ).toFixed(8)
                  }}
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("amount_coins_in_use") }}</b>
                  {{
                    (
                      (assetInfo.quantity - amountInOrigin) /
                      Math.pow(10, assetInfo.decimals)
                    ).toFixed(2)
                  }}
                  ({{
                    (
                      ((assetInfo.quantity - amountInOrigin) /
                        assetInfo.quantity) *
                      100
                    ).toFixed(2)
                  }}%)
                  <a
                    target="blank"
                    href="https://dev.pywaves.org/assets/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
                    >{{ $ml.get("coin_holders") }}</a
                  >
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("annual_return") }}</b>
                  <!--{{ annual_return && annual_return.toFixed(2) }} %-->
                  {{ average_revard && average_revard.toFixed(2) }} %
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("total_payout") }}</b>
                  {{ total_payout && total_payout.toFixed(0) }} USDT
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("ident") }}</b> {{ assetInfo.assetId }}
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("description") }}</b>
                  {{ assetInfo.description }}
                </div>
                <div class="text--primary mb-2">
                  <b>{{ $ml.get("price_in_market") }} </b>
                  <v-chip x-small dark color="red" v-on:click="openStock()"
                    >{{ marketStatus.ask / 1000000 }} USDT</v-chip
                  >
                  /
                  <v-chip x-small dark color="green" v-on:click="openStock()"
                    >{{ marketStatus.bid / 1000000 }} USDT</v-chip
                  >
                </div>
                <v-row justify="left">
                  <v-col cols="12" md="3">
                    <base-btn
                      class="mt-6"
                      :block="$vuetify.breakpoint.smAndDown"
                      color=""
                      href=""
                      large
                      outlined
                      target="_blank"
                      @click="gotoMarket"
                    >
                      {{ $ml.get("start") }}
                    </base-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <!--<v-card-actions>
                <v-btn
                  text
                  color="teal accent-4"
                  @click="reveal = true"
                  class="mb-4"
                >
                  {{ $ml.get("details") }}
                </v-btn>
              </v-card-actions>-->

              <!--<v-expand-transition>
                <v-card
                  v-if="reveal"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0" v-if="$ml.current == 'en'">
                    <b> The SYRF token has a limited supply. </b> <br />
                    Limited to existing assets. Additional issuance (issue) of a
                    token is made only on condition that there is a real
                    direction for investing these funds. (At this point in time,
                    more than 10% of the amount of already existing tokens in
                    circulation cannot be issued one-time, so as not to dilute
                    the share of existing holders and not reduce their profit)
                    <br />
                    <b>
                      All received profit from investment is divided like this:
                    </b>
                    <br />
                    10% is sent to the exchanger
                    <a
                      target="blank"
                      href="https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
                      >waves.exchange</a
                    >
                    and put on the buyout of the token in the SYRF / USDT pair
                    at the price of 0.9 USDT. <br />
                    30% goes to reinvestment and development to increase
                    profitability. <br />
                    60% is distributed among the holders of the SYRF token, in
                    proportion to their shares. Payments are made to the wallet
                    where SYRF tokens are stored, at least twice a month in
                    highly liquid tokens USDT, ETH, BTC, etc.
                  </v-card-text>
                  <v-card-text>
                    <p class="display-2 text--primary">
                      {{ $ml.get("distribution_scheme") }}
                    </p>
                    <p>
                      <b>{{ $ml.get("all_coins_distributed_as") }}</b> <br />
                    </p>
                    <p>
                      {{ $ml.get("scheme1") }} <br />
                      {{ $ml.get("scheme2") }}
                      <br />{{ $ml.get("scheme3") }}
                    </p>
                    <p>
                      {{ $ml.get("get_stable_profit_text") }}
                    </p>
                  </v-card-text>

                  <v-card-text class="pb-0" v-else>
                    <b>Токен SYRF имеет ограниченное количество.</b><br />
                    Количество ограниченно существующими активами.<br />
                    Дополнительное эмитирование (выпуск) токена производится
                    только при условии, что существует реальное направление для
                    инвестирования данных средств.<br />
                    (В данный момент времени не может быть разово эмитировано
                    более 10% от суммы уже существующих токенов в обороте, чтобы
                    не размывать долю уже существующих держателей и не уменьшать
                    их прибыль)<br />
                    <b
                      >Вся полученная прибыль, от инвестирования, делится
                      так:</b
                    ><br />
                    10% отправляется на обменник
                    <a
                      target="blank"
                      href="https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
                      >waves.exchange</a
                    >
                    и ставится на откуп токена в паре SYRF/USDT по цене 0,9
                    USDT.<br />
                    30% отправляется в повторное инвестирование и развитие, для
                    увеличения получения прибыли.<br />
                    60% распределяются между держателями токена SYRF, соразмерно
                    их долям. Выплаты производятся на кошелек где хранятся
                    токены SYRF, не реже двух раз в месяц в высоко ликвидных
                    токенах USDT, ETH, BTC и др.
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-btn text color="teal accent-4" @click="reveal = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
              -->
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-3">
          <v-col md="12">
            <v-card class="pa-4" elevation="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    SYRF/USDT
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="loaded"
                    >created at:
                    {{
                      chartdata.labels[
                        chartdata.labels.length - 1
                      ].toLocaleString("ru")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <LineChart
                v-if="loaded"
                :chartdata="chartdata"
                :options="options"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-3">
          <v-col md="12">
            <v-card class="pa-4" elevation="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ $ml.get("emit") }} SYRF
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >created at:
                    {{ new Date().toLocaleString("ru") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <BarChart
                v-if="loaded2"
                :chartdata="chartdata2"
                :options="options"
              />
            </v-card>
          </v-col>
        </v-row>
                <v-row class="my-3">
          <v-col md="12">
            <v-card class="pa-4" elevation="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ $ml.get("fired") }} SYRF
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >created at:
                    {{ new Date().toLocaleString("ru") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <BarChart
                v-if="loaded21"
                :chartdata="chartdata21"
                :options="options"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row class="my-5">
          <v-col md="12">
            <v-card class="pa-4" elevation="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ $ml.get("paid_in") }} USDT
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >created at:
                    {{ new Date().toLocaleString("ru") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <BarChart
                style="min-height: 500px"
                v-if="loaded3"
                :chartdata="chartdata3"
                :options="options"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-5">
          <v-col md="12">
            <v-card class="pa-4" elevation="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ $ml.get("profitability") }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >created at:
                    {{ new Date().toLocaleString("ru") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <BarChart
                v-if="loaded4"
                :chartdata="chartdata4"
                :options="options"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-3">
          <v-col md="12">
            <div class="text-center text-h5 font-weight-normal mb-4">
              {{ $ml.get("div_history") }}
            </div>
            <v-data-table
              :headers="headers"
              :items="payouts"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:header.id="{ header }">
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:item.id="{ item }">
                <div
                  @click="openLink(item.id)"
                  style="
                    display: inherit;
                    padding: 4px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    background: #416fad;
                    color: white;
                    border-radius: 14px;
                    cursor: pointer;
                  "
                >
                  {{ item.id }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="3">
            <base-btn
              class="mt-6"
              :block="$vuetify.breakpoint.smAndDown"
              color=""
              href=""
              large
              outlined
              target="_blank"
              @click="mailTo"
            >
              {{ $ml.get("send_to_support") }}
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
import LineChart from "../../components/LineChart.vue";
import BarChart from "../../components/BarChart.vue";
import axios from "axios";
var serverUrl = "https://syrf.info";
//var serverUrl = "https://syrfportal.azurewebsites.net";
//var serverUrl = "https://localhost:5001";

export default {
  name: "App",

  components: {
    LineChart,
    BarChart,
  },

  data() {
    return {
      mini: false,
      drawer: false,
      activePage: null,
      datacollection: null,
      assetInfo: null,
      marketStatus: null,
      reveal: false,
      amountInOrigin: 0,
      average_revard: 0,
      total_payout: 0,
      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Price",
            backgroundColor: "#9dc7f040",
            borderColor: "#9dc7f0",
            data: [],
          },
          {
            label: "Bid",
            backgroundColor: "#9dc71000",
            borderColor: "#9dc710",
            data: [],
          },
          {
            label: "Ask",
            backgroundColor: "#fdc71000",
            borderColor: "#f87979",
            data: [],
          },
        ],
      },
      chartdata2: {
        labels: [],
        datasets: [
          {
            label: "Issued",
            backgroundColor: "#f87979A0",
            borderColor: "#f87979",
            data: [],
          },
        ],
      },
      chartdata21: {
        labels: [],
        datasets: [
          {
            label: "Fired",
            backgroundColor: "#2499ffA0",
            borderColor: "#2499ff",
            data: [],
          },
        ],
      },
      chartdata3: {
        labels: [],
        datasets: [
          {
            label: "USDT",
            backgroundColor: "#9dc7f070",
            borderColor: "#9dc7f0",
            data: [],
          },
        ],
      },
      chartdata4: {
        labels: [],
        datasets: [
          {
            label: "%",
            backgroundColor: "#32a7c260",
            borderColor: "#32a7c2",
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 15,
                //min: 0,
                //max: 6,
                //stepSize: 1,
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              ticks: {
                min: 0,
                // max: 1,
                //stepSize: 0.1,
              },
            },
          ],
        },
      },

      loaded: false,
      loaded2: false,
      loaded21: false,
      loaded3: false,
      loaded4: false,

      headers: [
        {
          text: "Payment date",
          align: "start",
          sortable: true,
          value: "timestamp",
        },
        {
          text: "Transaction ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Comment",
          align: "start",
          sortable: false,
          value: "attachment",
        },
        {
          text: "In USDT",
          align: "start",
          sortable: true,
          value: "inUsdToInvestors",
        },
      ],
      payouts: [],
      debugEmit: [],
    };
  },
  mounted() {
    this.getAssetInfo();
    this.getAmountInOriginAcc();
    this.getMarketStatus();
    this.getEmitData();
    this.getFiredData();
    this.getUsdtPrice();
    setTimeout(this.getPayouts, 1000);
  },
  methods: {
    mailTo() {
      window.open("mailto:support@syrf.info?subject=Question%20From%20Site");
    },
    openStock() {
      window.open(
        "https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
      );
    },
    gotoMarket() {
      window.open(
        "https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
      );
    },
    getAssetInfo() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/details/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("prices usdt:", response.data);
          this.assetInfo = response.data;
        });
    },
    getMarketStatus() {
      axios
        .get(
          "https://matcher.waves.exchange/matcher/orderbook/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK/34N9YcEETLWn93qYQ64EsP1x89tSruJU44RrEMSXXEPJ/status"
        )
        .then((response) => {
          console.log("market status:", response.data);
          this.marketStatus = response.data;
        });
    },
    openLink(s) {
      window.open("https://wavesexplorer.com/tx/" + s, "_blank");
    },
    getUsdtPrice() {
      axios.get(serverUrl + "/api/GetPrices").then((response) => {
        console.log("prices usdt:", response.data);
        var t1 = response.data;

        this.chartdata.labels = this.chartdata.labels.concat(
          t1.map((x) => new Date(x.dateTime).toLocaleDateString("ru"))
        );

        this.chartdata.datasets[0].data =
          this.chartdata.datasets[0].data.concat(
            t1.map((x) => (x.bid + x.ask) / 2 / 1000000)
          );
        this.chartdata.datasets[1].data =
          this.chartdata.datasets[1].data.concat(
            t1.map((x) => x.bid / 1000000)
          );
        this.chartdata.datasets[2].data =
          this.chartdata.datasets[2].data.concat(
            t1.map((x) => x.ask / 1000000)
          );
        console.log(this.chartdata);

        this.loaded = true;
      });
    },
    //getEmitData() {
    //  axios
    //    .get(
    //      "https://nodes.wavesnodes.com/transactions/address/" +
    //        "3PA43tzsGHwR3gL1gqxCAFaUydwBgVhKJ7M" +
    //        "/limit/1000?after="
    //    )
    //    .then((response) => {
    //      var t = response.data[0]
    //        .filter((item) => item.type == 3 || item.type == 5)
    //        .reverse();
    //      console.log("эмитировано:", t);
    //      var x = response.data[0].filter((item) => item.type == 6).reverse();
    //      console.log("сожгли:", x);
    //      //////////////////////////////////////////////////////////////////////
    //      this.debugEmit = t.map((x) => ({
    //        amount: x.quantity,
    //        timestamp: x.timestamp,
    //      }));
    //      console.log("DUBUG EMIT: ", this.debugEmit);
    //      //////////////////////////////////////////////////////////////////////

    //      this.chartdata2.labels = t.map((x) =>
    //        new Date(x.timestamp).toLocaleDateString("ru")
    //      );
    //      this.chartdata2.datasets[0].data = t.map(
    //        (x) => x.quantity / 100000000
    //      );

    //      this.chartdata2.datasets[1].data = x.map((y) => y.amount / 100000000);

    //      console.log(this.chartdata2);

    //      this.loaded2 = true;
    //    });
    //},
    getEmitData() {
      axios.get(serverUrl + "/api/GetEmitTransactions").then((response) => {
        var t = response.data;
        console.log("EMIT: ", t);

        this.chartdata2.labels = t.map((x) => new Date(x.dateTime).toLocaleDateString("ru"));
        this.chartdata2.datasets[0].data = t.map((x) => x.Amount / 100000000);

        this.loaded2 = true;
      });
    },
    getFiredData() {
      axios.get(serverUrl + "/api/GetFiredTransactions").then((response) => {
        var t = response.data;
        console.log("FIRED: ", t);

        this.chartdata21.labels = t.map((x) => new Date(x.dateTime).toLocaleDateString("ru"));
        this.chartdata21.datasets[0].data = t.map((y) => y.Amount / 100000000);
        console.log("chartdata21", this.chartdata21);
        this.loaded21 = true;
      });
    },
    getPayouts() {
      axios.get(serverUrl + "/api/GetPayouts").then((response) => {
        var t = response.data;
        console.log("отправок: ", t);

        //для графика выплат
        var totals = t.reduce(function (r, o) {
          r[
            new Date(o.timestamp).toLocaleDateString("ru", {
              month: "2-digit",
              year: "numeric",
            })
          ]
            ? (r[
                new Date(o.timestamp).toLocaleDateString("ru", {
                  month: "2-digit",
                  year: "numeric",
                })
              ] += o.inUsdToInvestors)
            : (r[
                new Date(o.timestamp).toLocaleDateString("ru", {
                  month: "2-digit",
                  year: "numeric",
                })
              ] = o.inUsdToInvestors);
          return r;
        }, {});

        //(группируем по месяцам с суммированием)
        this.chartdata3.labels = Object.keys(totals);
        this.chartdata3.datasets[0].data = Object.values(totals).map((x) =>
          (x / 1000000).toFixed(6)
        );

        //this.chartdata3.labels = t.map((x) =>
        //  new Date(x.timestamp).toLocaleDateString("ru")
        //);
        //this.chartdata3.datasets[0].data = t.map((x) => x.inUsdToInvestors / 1000000);

        //для графика доходности % (группируем по месяцам с суммированием)
        ///////////////TODO: переделать формулу чтобы учитывать динамику выпуска новых монет///////////////
        var payouts = t.map((x) => ({
          amount: x.inUsdToInvestors,
          timestamp: x.timestamp,
          inUsage: x.tokensEmitted - x.tokensInEmitWallet,
          emit: x.tokensEmitted,
        }));

        console.log("DUBUG: ", payouts);

        /////////////////////////////////////////////////////////////////

        //для графика % доходности
        var totals2 = payouts.reduce(function (r, o) {
          r[
            new Date(o.timestamp).toLocaleDateString("ru", {
              month: "2-digit",
              year: "numeric",
            })
          ]
            ? (r[
                new Date(o.timestamp).toLocaleDateString("ru", {
                  month: "2-digit",
                  year: "numeric",
                })
              ] += (10000 * o.amount) / o.inUsage)
            : (r[
                new Date(o.timestamp).toLocaleDateString("ru", {
                  month: "2-digit",
                  year: "numeric",
                })
              ] = (10000 * o.amount) / o.inUsage);
          return r;
        }, {});
        console.log("totals2", totals2);

        this.chartdata4.labels = Object.keys(totals2);
        this.chartdata4.datasets[0].data = Object.values(totals2).map((x) =>
          x.toFixed(2)
        );
        console.log("data4: ", this.chartdata4.datasets[0].data);

        ///////вычислим среднюю доходность за последние 12 месяцев////
        for (
          let i = this.chartdata4.datasets[0].data.length - 13;
          i < this.chartdata4.datasets[0].data.length - 1;
          i++
        ) {
          this.average_revard += Number(this.chartdata4.datasets[0].data[i]);
        }
        this.average_revard = this.average_revard;

        console.log("average_revard:", this.average_revard);

        ////////////////////////////////////////////////////////////

        this.loaded4 = true;

        //для таблицы истории выплат
        const bs58 = require("bs58");
        this.payouts = t
          .map(function (x) {
            return {
              id: x.id,
              timestamp: new Date(x.timestamp).toLocaleString("ru"),
              attachment: bs58.decode(x.attachment).toString(),
              inUsdToInvestors: (x.inUsdToInvestors / 1000000).toFixed(6),
            };
          })
          .reverse();

        console.log("выплачено в USDT:", this.payouts);

        this.payouts
          .map((x) => x.inUsdToInvestors)
          .forEach((element) => {
            this.total_payout += Number(element);
          });

        console.log("total_payout:", this.total_payout);
        this.loaded3 = true;
      });
    },
    //получаем количество монет в обороте(вычитаем из общего количества количество монет на кошельке-эмитенте)
    getAmountInOriginAcc() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/balance/3PA43tzsGHwR3gL1gqxCAFaUydwBgVhKJ7M/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("amount in origin addr:", response.data.balance);
          this.amountInOrigin = response.data.balance;
        });
    },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-application--is-ltr .v-data-table__mobile-row__cell div {
  text-align: left;
  word-wrap: break-word;
  max-width: 195px !important;
}
</style>